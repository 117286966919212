<template>
	<div>
		<van-nav-bar
			title="商品詳情"
			fixed
			left-arrow
			class="qjc-nav-bar"
			 @click-left="$router.back()"/>
		<div class="goods">
			<div class="goods_box">
				<van-image class="goods_img" :src="require('@/assets/images/vaccines/goods_img.png')"></van-image>
			</div>
			<div class="goods_info">
				<div class="goods_price"><span class="goods_unit">￥</span>{{price}}</div>
				<div class="goods_detail">{{name}}（含预约费+疫苗费用）</div>
			</div>
			<div class="goods_foot">
				<van-button class="goods_btn" @click="handleToPay">立即购买</van-button>
			</div>
		</div>
		
		
		
	</div>
</template>

<script>
	import { isPay } from '@/js/axios'
	
	import Vue from 'vue'
	import { NavBar, Image, Cell, Button, Tabbar, TabbarItem, Toast, Popup, Dialog } from 'vant'
	Vue.use(NavBar)
	   .use(Image)
	   .use(Cell)
	   .use(Button)
	   .use(Tabbar)
	   .use(TabbarItem)
	   .use(Toast)
	   .use(Popup)
	   .use(Dialog);
	
	export default{
		name: 'vaccinesGoods',
		data (){
			return {
				price: '',
				name: '',
			}
		},
		computed: {
			
		},
		mounted() {
			let clientWidth = document.documentElement.clientWidth;
			let clientHeight = document.documentElement.clientHeight;
			let ratio = clientWidth / 375;
			let list_height = clientHeight - (3.26 * 100 * ratio / 2);
			this.height = (list_height/100)/ratio * 2; //历史记录 高度
			
			document.querySelector('body').setAttribute('style', 'background-color:#F5F7FA')
		},
		beforeDestroy() {
			document.querySelector('body').removeAttribute('style')
		},
		methods: {
			handleToPay() {
				this.$router.push('/vaccines/clause_hpv')
			},
		}
	}
</script>

<style lang="scss" scoped>
	.goods{
		width: 100%;
		min-height: 100vh;
		padding: 0.88rem 0;
		background: #F5F6FA;
	}
	.goods_box {
	    width: 100%;
	    height: 7.50rem; 
	}
	.goods_img {
	    width: 100%;
	    height: 7.50rem;
	}
	
	.goods_info {
	    width: 100%;
	    padding: 0.30rem;
	    background-color: #fff;
		text-align: left;
	}
	.goods_price {
	    font-size: 0.60rem;
	    font-family: MiSans-Demibold, MiSans;
	    font-weight: 600;
	    color: #FA5151;
	    line-height: 0.44rem;
	}
	.goods_unit {
	    font-size: 0.36rem;
	    font-family: MiSans-Demibold, MiSans;
	    font-weight: 600;
	    color: #FA5151;
	    line-height: 0.44rem;
	}
	.goods_detail {
	    font-size: 0.32rem;
	    font-family: PingFang SC-Medium, PingFang SC;
	    font-weight: 500;
	    color: #32363E;
	    line-height: 0.38rem;
	    margin-top: 0.28rem;
	}
	
	.goods_foot {
	    width: 100%;
	    padding: 0 0.30rem;
	    margin-top: 0.80rem;
	}
	.goods_btn {
	    width: 100% !important;
	    height: 0.88rem;
	    font-size: 0.40rem;
	    font-family: PingFang SC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #FFFFFF;
	    border-radius: 0.16rem;
	    background-color: #6681FA;
	}
</style>
